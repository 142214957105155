import { db, fieldPath } from "~/setups/firebase";
import { AppointmentStatus, envVars, EnvVars } from "@ambii-com/core";
import {
  ClinicLifeCycleState,
  SubmissionLifeCycleState,
  FormQuestionComponents,
} from "@ambii-com/core";
import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  FirebaseFirestore,
  Query,
  Timestamp,
  DocumentSnapshot,
} from "@firebase/firestore-types";

class FsLocations {
  clinicsRef: CollectionReference<DocumentData>;
  // usersRef: CollectionReference<DocumentData>
  patientsRef: CollectionReference<DocumentData>;
  adminsRef: CollectionReference<DocumentData>;
  appointmentMenusRef: CollectionReference<DocumentData>;
  appointmentSchedulesRef: CollectionReference<DocumentData>;
  scheduleResourceSettingsRef: CollectionReference<DocumentData>;
  appointmentsRef: CollectionReference<DocumentData>;
  formsRef: CollectionReference<DocumentData>;
  formConfigsRef: CollectionReference<DocumentData>;
  vaccineConfigsRef: CollectionReference<DocumentData>;
  formSubmissionsRef: CollectionReference<DocumentData>;
  formSectionsRef: CollectionReference<DocumentData>;
  formPagesRef: CollectionReference<DocumentData>;
  formQuestionsRef: CollectionReference<DocumentData>;
  appointmentConfigsRef: CollectionReference<DocumentData>;
  postsRef: CollectionReference<DocumentData>;
  scoringGroupsRef: CollectionReference<DocumentData>;
  db: FirebaseFirestore;
  envVars: EnvVars;

  constructor(_db: FirebaseFirestore = db, _envVars: EnvVars = envVars) {
    this.db = _db;
    this.envVars = _envVars;
    this.clinicsRef = this.db.collection(this.envVars.clinicCollectionName);
    // this.usersRef = this.db.collection(this.envVars.userCollectionName)
    this.patientsRef = this.db.collection(this.envVars.patientCollectionName);
    this.adminsRef = this.db.collection(this.envVars.adminCollectionName);
    this.appointmentMenusRef = this.db.collection(
      this.envVars.appointmentMenusCollectionName
    );
    this.appointmentSchedulesRef = this.db.collection(
      this.envVars.appointmentSchedulesCollectionName
    );
    this.scheduleResourceSettingsRef = this.db.collection(
      this.envVars.scheduleResourceSettingCollectionName
    );
    this.appointmentsRef = this.db.collection(
      this.envVars.scheduleAppointmentCollectionName
    );
    this.formConfigsRef = this.db.collection(
      this.envVars.formsConfigCollectionName
    );
    this.appointmentConfigsRef = this.db.collection(
      this.envVars.appointmentConfigsCollectionName
    );
    this.vaccineConfigsRef = this.db.collection(
      this.envVars.vaccineConfigCollectionName
    );
    this.formSubmissionsRef = this.db.collection(
      this.envVars.submissionsCollectionName
    );
    this.formsRef = this.db.collection(this.envVars.formsCollectionName);
    this.formPagesRef = this.db.collection(
      this.envVars.formPagesCollectionName
    );
    this.formQuestionsRef = this.db.collection(
      this.envVars.questionsCollectionName
    );
    this.postsRef = this.db.collection(this.envVars.postsCollectionName);
    this.scoringGroupsRef = this.db.collection(
      this.envVars.scoringGroupsCollectionName
    );
  }
  getClinicRef(clinicId: ClinicId): DocumentReference<DocumentData> {
    return this.clinicsRef.doc(clinicId);
  }
  getClinicsRef(): CollectionReference<DocumentData> {
    return this.clinicsRef;
  }
  getClinicRefByUserId(userId: UserId): Query<DocumentData> {
    return this.clinicsRef.where("admin.uuid", "==", userId);
  }
  getMenuRef(menuId: MenuId): DocumentReference<DocumentData> {
    return this.appointmentMenusRef.doc(menuId);
  }
  getMenusRefByClinicId(clinicId: ClinicId): Query<DocumentData> {
    return this.appointmentMenusRef.where("clinicId", "==", clinicId);
  }
  getMenusForPatientRefByClinicId(clinicId: ClinicId): Query<DocumentData> {
    return this.getMenusRefByClinicId(clinicId).where(
      "shownForPatient",
      "==",
      true
    );
  }
  // getScheduleResourcesRefByMenuId(menuId: MenuId): Query<DocumentData> {
  //   return this.scheduleResourcesRef.where("menuId", "==", menuId);
  // }
  // getScheduleResourcesForPatientRefByMenuId(
  //   menuId: MenuId
  // ): Query<DocumentData> {
  //   return this.getScheduleResourcesRefByMenuId(menuId).where(
  //     "shownForPatient",
  //     "==",
  //     true
  //   );
  // }
  // getScheduleResourcesRefByClinicId(clinicId: ClinicId): Query<DocumentData> {
  //   return this.scheduleResourcesRef.where("clinicId", "==", clinicId);
  // }
  // getScheduleResourceRef(
  //   resourceId: ResourceId
  // ): DocumentReference<DocumentData> {
  //   return this.scheduleResourcesRef.doc(resourceId);
  // }
  getScheduleResourceSettingRef(
    resourceId: ResourceId
  ): DocumentReference<DocumentData> {
    return this.scheduleResourceSettingsRef.doc(resourceId);
  }
  getScheduleRef(scheduleId: ScheduleId): DocumentReference<DocumentData> {
    return this.appointmentSchedulesRef.doc(scheduleId);
  }
  getSchedulesRefByMenuIdLegacy(menuId: MenuId): Query<DocumentData> {
    return this.appointmentSchedulesRef.where("resourceId", "==", menuId);
  }
  getSchedulesRefByMenuId(menuId: MenuId): Query<DocumentData> {
    return this.appointmentSchedulesRef.where(
      "resourceId",
      "array-contains",
      menuId
    );
  }
  // TODO rename
  getScheduleBucketsRefByClinicId(clinicId: ClinicId): Query<DocumentData> {
    return this.appointmentSchedulesRef.where("clinicId", "==", clinicId);
  }
  getScheduleBucketsRefByResourceId(
    resourceId: ResourceId
  ): Query<DocumentData> {
    return this.appointmentSchedulesRef.where("resourceId", "==", resourceId);
  }
  getScheduleBucketsRefbyResourceIdAndClinicId(
    resourceId: ResourceId,
    clinicId: ClinicId
  ): Query<DocumentData> {
    return this.appointmentSchedulesRef
      .where("resourceId", "==", resourceId)
      .where("clinicId", "==", clinicId);
  }
  getScheduleBucketsRefByResourceIdAndDate(
    resourceId: ResourceId,
    date: string
  ): Query<DocumentData> {
    return this.appointmentSchedulesRef
      .where("resourceId", "==", resourceId)
      .where("date", "==", date);
  }
  getAppointmentScheduleRef(
    scheduleId?: ScheduleId
  ): DocumentReference<DocumentData> {
    return this.appointmentSchedulesRef.doc(scheduleId);
  }
  getScheduleAppointmentsRefByClinicIdAndMenuId(
    clinicId: ClinicId,
    menuId: MenuId
  ): Query<DocumentData> {
    return this.appointmentsRef
      .where("menuId", "==", menuId)
      .where("clinicId", "==", clinicId);
  }
  getScheduleAppointmentsRefByBucketId(
    bucketId: BucketId
  ): Query<DocumentData> {
    return this.appointmentsRef.where("bucketId", "==", bucketId);
  }
  // Note: in order for the fs rules for admins to work, querys must also contain clinicId
  getScheduleAppointmentsRefByClinicIdAndBucketId(
    clinicId: ClinicId,
    bucketId: BucketId
  ): Query<DocumentData> {
    return this.appointmentsRef
      .where("bucketId", "==", bucketId)
      .where("clinicId", "==", clinicId);
  }
  getScheduleAppointmentsRefByClinicIdAndBucketIdAndDate(
    clinicId: ClinicId,
    bucketId: BucketId,
    date: string
  ): Query<DocumentData> {
    return this.getScheduleAppointmentsRefByClinicIdAndBucketId(
      clinicId,
      bucketId
    ).where("date", "==", date);
  }
  getScheduleAppointmentsRefByBucketIdAndUserId(
    bucketId: BucketId,
    userId: UserId
  ): Query<DocumentData> {
    return this.appointmentsRef
      .where("bucketId", "==", bucketId)
      .where("userId", "==", userId);
  }
  getScheduleAppointmentsRefByUserId(userId: UserId): Query<DocumentData> {
    return this.appointmentsRef.where("userId", "==", userId);
  }
  // getScheduleAppointmentsRefByMenuIdAndUserId(menuId: MenuId, userId: UserId): Query<DocumentData> {
  //   return this.appointmentsRef.where("menuId", "==", menuId).where("userId", "==", userId)
  // }
  getScheduleAppointmentsRefByResourceIdAndUserId(
    resourceId: ResourceId,
    userId: UserId
  ): Query<DocumentData> {
    return this.appointmentsRef
      .where("resourceId", "==", resourceId)
      .where("userId", "==", userId);
  }
  getScheduleAppointmentsRefByClinicId(
    clinicId: ClinicId
  ): Query<DocumentData> {
    return this.appointmentsRef.where("clinicId", "==", clinicId);
  }
  getScheduleAppointmentsRefByClinicIdAndStatusIsCanceled(
    clinicId: ClinicId
  ): Query<DocumentData> {
    return this.appointmentsRef
      .where("clinicId", "==", clinicId)
      .where("status", "==", AppointmentStatus.CANCELED);
  }
  getScheduleAppointmentsRefByClinicIdAndClinicLifeCycleState(
    clinicId: ClinicId,
    clinicLifeCycleState: ClinicLifeCycleState
  ): Query<DocumentData> {
    return this.getScheduleAppointmentsRefByClinicId(clinicId).where(
      "clinicLifeCycleState",
      "==",
      clinicLifeCycleState
    );
  }
  getScheduleAppointmentsRefByClinicIdAndDate(
    clinicId: ClinicId,
    date: string
  ): Query<DocumentData> {
    return this.appointmentsRef
      .where("clinicId", "==", clinicId)
      .where("date", "==", date);
  }
  getScheduleAppointmentsRefByClinicIdAndDateRange(
    clinicId: ClinicId,
    startDate: string,
    endDate: string
  ): Query<DocumentData> {
    return this.appointmentsRef
      .where("clinicId", "==", clinicId)
      .where("date", ">=", startDate)
      .where("date", "<=", endDate);
  }
  getScheduleAppointmentsRefByClinicIdAndDateAndClinicLifeCycleState(
    clinicId: ClinicId,
    date: string,
    clinicLifeCycleState: ClinicLifeCycleState
  ): Query<DocumentData> {
    return this.getScheduleAppointmentsRefByClinicIdAndDate(
      clinicId,
      date
    ).where("clinicLifeCycleState", "==", clinicLifeCycleState);
  }
  getScheduleAppointmentsRefByClinicIdAndMenuIdAndClinicLifeCycleState(
    clinicId: ClinicId,
    menuId: string,
    clinicLifeCycleState: ClinicLifeCycleState
  ): Query<DocumentData> {
    return this.getScheduleAppointmentsRefByClinicIdAndMenuId(
      clinicId,
      menuId
    ).where("clinicLifeCycleState", "==", clinicLifeCycleState);
  }
  getScheduleAppointmentRef(
    appointmentId: AppointmentId
  ): DocumentReference<DocumentData> {
    return this.appointmentsRef.doc(appointmentId);
  }
  getPatientsRef(userId: UserId): DocumentReference<DocumentData> {
    return this.patientsRef.doc(userId);
  }
  getAdminsRef(userId: UserId): DocumentReference<DocumentData> {
    return this.adminsRef.doc(userId);
  }
  getPatientsRefByUserId(userId: UserId) {
    return this.patientsRef.where("uid", "==", userId).limit(1);
  }
  getAdminsRefByUserId(userId: UserId) {
    return this.adminsRef.where("uid", "==", userId).limit(1);
  }
  getFormConfigRef(configId: string): DocumentReference<DocumentData> {
    return this.formConfigsRef.doc(configId);
  }
  getFormConfigRefByShortLinkPath(shortLinkPath: string): Query<DocumentData> {
    return this.formConfigsRef
      .where("shortLinkPath", "==", shortLinkPath)
      .limit(1);
  }
  // TODO Add clinicId to all querys when access controls based on clinicId are added
  getFormsRefByClinicId(clinicId: ClinicId): CollectionReference<DocumentData> {
    return this.getClinicRef(clinicId).collection(
      this.envVars.formsCollectionName
    );
  }
  getFormRefsByClinicId(clinicId: ClinicId): Query<DocumentData> {
    return this.formsRef.where("clinicId", "==", clinicId);
  }
  getFormTemplateRefs(): Query<DocumentData> {
    return this.formsRef.where("isTemplate", "==", true);
  }
  getFormSubmissionRef(id: string) {
    return this.formSubmissionsRef.doc(id);
  }
  getFormSubmissionRefByAppointmentId(
    appointmentId: AppointmentId
  ): Query<DocumentData> {
    return this.formSubmissionsRef.where("appointmentId", "==", appointmentId);
  }
  getFormRef(formId: FormId) {
    return this.formsRef.doc(formId);
  }
  getFormRefByAppointmentId(
    clinicId: ClinicId,
    appointmentId: string
  ): Query<DocumentData> {
    return this.getFormsRefByClinicId(clinicId)
      .where("appointment.appointmentId", "==", appointmentId)
      .limit(1);
  }
  getFormSubmissionRefByClinicId(clinicId: ClinicId) {
    return this.formSubmissionsRef.where("clinicId", "==", clinicId);
  }
  getFormSubmissionsByClinicIdAndState(
    clinicId: ClinicId, 
    lifeCycleStates: SubmissionLifeCycleState[],
    currentDoc: DocumentSnapshot,
    direction: 'prev' | 'next'
  ) {
    const baseQuery = this.getFormSubmissionRefByClinicId(clinicId)
      .where("lifeCycleState", "in", lifeCycleStates)
      .orderBy("createdAt", "desc");

    if (direction === 'prev') {
      return baseQuery
        .endBefore(currentDoc)
        .limitToLast(1)
        .get();
    } else {
      return baseQuery
        .startAfter(currentDoc)
        .limit(1)
        .get();
    }
  }
  getPrevFormSubmissionByClinicId(clinicId: ClinicId, currentDoc: DocumentSnapshot) {
    return this.getFormSubmissionsByClinicIdAndState(
      clinicId,
      [SubmissionLifeCycleState.CREATED, SubmissionLifeCycleState.UNARCHIVED],
      currentDoc,
      'prev'
    );
  }
  getNextFormSubmissionByClinicId(clinicId: ClinicId, currentDoc: DocumentSnapshot) {
    return this.getFormSubmissionsByClinicIdAndState(
      clinicId,
      [SubmissionLifeCycleState.CREATED, SubmissionLifeCycleState.UNARCHIVED],
      currentDoc,
      'next'
    );
  }
  getPrevArchivedFormSubmissionByClinicId(clinicId: ClinicId, currentDoc: DocumentSnapshot) {
    return this.getFormSubmissionsByClinicIdAndState(
      clinicId,
      [SubmissionLifeCycleState.ARCHIVED],
      currentDoc,
      'prev'
    );
  }
  getNextArchivedFormSubmissionByClinicId(clinicId: ClinicId, currentDoc: DocumentSnapshot) {
    return this.getFormSubmissionsByClinicIdAndState(
      clinicId,
      [SubmissionLifeCycleState.ARCHIVED],
      currentDoc,
      'next'
    );
  }
  getFormSubmissionRefByClinicIdAndDateRange(
    clinicId: ClinicId,
    startDate: Timestamp,
    endDate: Timestamp
  ) {
    return this.formSubmissionsRef
      .where("clinicId", "==", clinicId)
      .where("createdAt", ">=", startDate)
      .where("createdAt", "<=", endDate);
  }
  getCreatedFormSubmissionRefByClinicId(clinicId: ClinicId) {
    return this.formSubmissionsRef
      .where("clinicId", "==", clinicId)
      .where("lifeCycleState", "==", SubmissionLifeCycleState.CREATED);
  }
  getActiveFormSubmissionRefByClinicId(clinicId: ClinicId) {
    return this.formSubmissionsRef
      .where("clinicId", "==", clinicId)
      .where("lifeCycleState", "in", [
        SubmissionLifeCycleState.CREATED,
        SubmissionLifeCycleState.UNARCHIVED,
      ]);
  }
  getArchivedFormSubmissionRefByClinicId(clinicId: ClinicId) {
    return this.formSubmissionsRef
      .where("clinicId", "==", clinicId)
      .where("lifeCycleState", "==", SubmissionLifeCycleState.ARCHIVED);
  }
  getFormSectionRef(id: string) {
    return this.formSectionsRef.doc(id);
  }
  getFormPageRef(id: string) {
    return this.formPagesRef.doc(id);
  }
  getFormPageRefsByClinicId(clinicId: ClinicId): Query<DocumentData> {
    return this.formPagesRef.where("clinicId", "==", clinicId);
  }
  getFormPageRefsByFormId(formId: FormId): Query<DocumentData> {
    return this.formPagesRef.where("formId", "==", formId);
  }
  getFormQuestionRef(questionId: QuestionId): DocumentReference<DocumentData> {
    return this.formQuestionsRef.doc(questionId);
  }
  getFormQuestionRefByPageId(pageId: PageId): Query<DocumentData> {
    return this.formQuestionsRef.where("pageId", "==", pageId);
  }
  getFormQuestionRefByFormId(formId: FormId): Query<DocumentData> {
    return this.formQuestionsRef.where("formId", "==", formId);
  }
  // requires firestore index in prd
  getPatientInfoFormQuestionRefsByFormId(formId: FormId) {
    return this.getFormQuestionRefByFormId(formId).where(
      "dashboardMetadata.patientInfoTag",
      "!=",
      null
    );
  }
  getVisitReasonFormQuestionRefsByFormId(formId: FormId) {
    return this.getFormQuestionRefByFormId(formId).where(
      "componentType",
      "==",
      FormQuestionComponents.VISIT_REASON_FIELD
    );
  }
  // getDependentQuestionsByClinicIdAndFormId(clinicId: ClinicId, formId: FormId): Query<DocumentData>{
  //   return this.formQuestionsRef
  //     .where("clinicId", "==", clinicId)
  //     .where("formId", "==", formId)
  //     .where('dependencies', '!=', null)
  // }
  getVaccineConfigRef(vaccineConfigId: string) {
    return this.vaccineConfigsRef.doc(vaccineConfigId);
  }
  getVaccineConfigRefByClinicId(clinicId: ClinicId): Query<DocumentData> {
    return this.vaccineConfigsRef.where("clinicId", "==", clinicId);
  }
  getAppointmentConfigRef(appointmentConfigId: string) {
    return this.appointmentConfigsRef.doc(appointmentConfigId);
  }
  getAppointmentConfigRefByClinicId(clinicId: ClinicId): Query<DocumentData> {
    return this.appointmentConfigsRef.where("clinicId", "==", clinicId);
  }
  getPostsRefByClinicId(clinicId: ClinicId): Query<DocumentData> {
    return this.postsRef.where("clinicId", "==", clinicId);
  }
  getScoringGroupRef(scoringGroupId: string) {
    return this.scoringGroupsRef.doc(scoringGroupId);
  }
  getScoringGroupsRefByClinicId(clinicId: ClinicId): Query<DocumentData> {
    return this.scoringGroupsRef.where("clinicId", "==", clinicId);
  }
  getNotDeletedScoringGroupsRefByClinicId(
    clinicId: ClinicId
  ): Query<DocumentData> {
    return this.getScoringGroupsRefByClinicId(clinicId).where(
      "deleted",
      "==",
      false
    );
  }
}
const fsLocations = new FsLocations();

export { FsLocations, fsLocations };
