import { fsLocations } from "~/dao/FsLocations";
import { UserRole } from "@ambii-com/core";
import { FormSubmission } from "@ambii-com/core";

export default async function ({ app, store, redirect, route }) {
  if (!store.getters["auth/isLoggedIn"]) {
    return redirect({ path: "/login", query: { from: route.path } });
  } else if (!store.getters["auth/checkSuperadmin"]) {
    const isAdmin: boolean = await fsLocations
      .getAdminsRefByUserId(store.getters["auth/getUserId"])
      .get()
      .then((adminQuery) => {
        const role = adminQuery.docs[0].data().role;
        return adminQuery.empty
          ? false
          : role === UserRole.CLINIC || role == "STAFF"; // TODO: add to UserRole enum
      });
    if (!isAdmin) {
      alert(app.i18n.t("middleware.pleaseUseAdminAccount"));
      await store.dispatch("auth/logout");
      return redirect({
        path: app.localePath("/login"),
        query: { from: route.path },
      });
    }
  }

  // check if submission ID or form ID belongs to logged in clinic user
  // submission details page
  if (route.params.hasOwnProperty("submissionId")) {
    const submissionId = route.params.submissionId;
    const submission = await fsLocations
      .getFormSubmissionRef(submissionId)
      .get()
      .then(FormSubmission.fromDoc);
    const clinicId = submission.clinicId;
    if (clinicId !== store.getters["auth/getClinicId"]) {
      await store.dispatch("auth/logout");
      return redirect({
        path: app.localePath("/login"),
        query: { from: route.path },
      });
    }
  }
  // for form build pages
  else if (route.params.hasOwnProperty("formId")) {
    const formId = route.params.formId;
    const form = await fsLocations
      .getFormRef(formId)
      .get()
      .then(FormSubmission.fromDoc);
    const clinicId = form.clinicId;
    if (clinicId !== store.getters["auth/getClinicId"]) {
      await store.dispatch("auth/logout");
      return redirect({
        path: app.localePath("/login"),
        query: { from: route.path },
      });
    }
  }
}
